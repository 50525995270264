export const Institutes = [
  {
    name: "Navitas Professional",
    url: "/navitas-professional",
    sDescription: `Navitas Professional is part of the very prestigious Navitas
    Group- An S&P ASX 100 company which has established itself as a pioneering global
    educator helping more than 80,000 domestic and international students realize and
    develop their potential through quality education. Established in 1994, Navitas is an
    S&P ASX 100 company which employs more than 6,000 staff across 31 countries.`,
    logo: "/assets/images/navitas_big_logo.webp",
    availableProgram: [
      {
        name: "Professional Year in Accounting",
        location: [
          "Adelaide",
          "Brisbane",
          "Canberra",
          "Darwin",
          "Hobart",
          "Melbourne",
          "Perth",
          "Sydney",
        ],
      },
      {
        name: "Professional Year in Engineering",
        location: [
          "Adelaide",
          "Brisbane",
          "Canberra",
          "Darwin",
          "Hobart",
          "Melbourne",
          "Perth",
          "Sydney",
        ],
      },
      {
        name: "Professional Year in IT",
        location: [
          "Adelaide",
          "Brisbane",
          "Canberra",
          "Darwin",
          "Hobart",
          "Melbourne",
          "Perth",
          "Sydney",
        ],
      },
      {
        name: "NAATI Endorsed Courses",
        location: [
          "Adelaide",
          "Brisbane",
          "Canberra",
          "Darwin",
          "Hobart",
          "Melbourne",
          "Perth",
          "Sydney",
        ],
      },
    ],
  },
  {
    name: "Monash Professional Pathways",
    url: "/monash-professional-pathways",
    sDescription: `Since its foundation in 2001, Monash Professional Pathways has
    been supporting graduates who seek professional experience and careers in Australia. The
    university specialise in assisting international students gain employment skills and
    industry experience through training and vocational placements.`,
    logo: "/assets/images/monash-college-professional-pathways.webp",
    availableProgram: [
      {
        name: "Professional Year in Accounting",
        location: ["Melbourne"],
      },
      {
        name: "Professional Year in Engineering",
        location: ["Melbourne"],
      },
      {
        name: "Professional Year in IT",
        location: ["Melbourne"],
      },
      {
        name: "NAATI Endorsed Courses",
        location: ["Melbourne"],
      },
    ],
  },
  {
    name: "Performance Education",
    url: "/performance-education",
    sDescription: ` Performance Education’s Professional Year Program is aimed at
    taking employability skills of professional year students to the next level. Their
    programs offer graduates, a never like before opportunity to nurture their employability
    skills through a blend of classroom training and a 12 week internship at an Australian
    host company for a practical Australian workplace experience.`,
    logo: "/assets/images/preformance-education-logo.webp",
    availableProgram: [
      {
        name: "Professional Year in Accounting",
        location: ["Adelaide", "Brisbane", "Melbourne", "Sydney"],
      },
      {
        name: "Professional Year in IT",
        location: ["Adelaide", "Brisbane", "Melbourne", "Sydney"],
      },
      {
        name: "NAATI Endorsed Courses",
        location: ["Adelaide", "Brisbane", "Melbourne", "Sydney"],
      },
    ],
  },
  {
    name: "Education Centre of Australia",
    url: "/education-centre-australia",
    sDescription: `Education Centre of Australia (ECA), is a progressive group dedicated to providing quality education to global students. This dynamic group operates several educational institutions within Australia and boasts upon a vast network across the globe. `,
    logo: "/assets/images/ECA_logo.webp",
    availableProgram: [
      {
        name: "Professional Year in IT",
        location: ["Brisbane", "Sydney", "Melbourne", "Geelong"],
      },
      {
        name: "NAATI Endorsed Courses",
        location: ["Brisbane", "Sydney", "Melbourne", "Geelong"],
      },
    ],
  },
  {
    name: "Queensland International Business Academy",
    url: "/queensland-international-business-academy",
    sDescription: `If you have been in a legal venture capital agreement with an
    Australian organisation or
    company that is a member of the Australian Private Equity and Venture Capital
    Association
    Limited (AVCAL).`,
    logo: "/assets/images/QIBA_logo.webp",
    availableProgram: [
      {
        name: "Professional Year in IT",
        location: ["Canberra", "Hobart", "Sydney", "Brisbane", "Gold Coast"],
      },
      {
        name: "NAATI Endorsed Courses",
        location: ["Canberra", "Hobart", "Sydney", "Brisbane", "Gold Coast"],
      },
    ],
  },
  {
    name: "Stanley College",
    url: "/stanley-college",
    sDescription: `Stanley College (CRICOS Code: 03047E | RTO Code: 51973) is a
    registered training organisation located in Perth, Western Australia. Stanley College
    has three campuses offering 23 nationally recognised courses in Translation and
    Interpreting, Business and Management, Hospitality, Commercial Cookery and Patisserie,
    Health, Security and Early Childhood Education to international students. Stanley
    College also offers a General English (ELICOS) course to international students.`,
    logo: "/assets/images/stanley-college-logo.webp",
    availableProgram: [
      {
        name: "Professional Year in Accounting",
        location: ["Adelaide", "Perth"],
      },
      {
        name: "NAATI Endorsed Courses",
        location: ["Perth"],
      },
    ],
  },
  {
    name: "Nit Australia",
    url: "/nit-australia",
    sDescription: `NIT Australia successfully run the ACS (Australian Computer
        Society) Professional Year Program since 2015, with a 100% internship placement rate.
        They pride themselves in delivering the best goal-matched IT internships and
        personalised IT career support, with the best mock job interview experience provided to
        the students throughout the 1-year program.`,
    logo: "/assets/images/nit-logo.webp",
    availableProgram: [
      {
        name: "Professional Year in Engineering",
        location: ["Perth"],
      },
      {
        name: "Professional Year in IT",
        location: ["Perth"],
      },
      {
        name: "NAATI Endorsed Courses",
        location: ["Perth"],
      },
    ],
  },
];

export const ProgramNames = [
  {
    url: "/accounting-program",
    name: "Professional Year in Accounting",
    isshow: true,
    about:
      "The Accounting Professional Year Program is developed by three organizations, namely the CPA Australia, ICA (Institute of Chartered Accountants) and IPA (Institute of Public Accountants. The Accounting PYP was formerly known as the SMIPA, and it helps international accounting graduates to develop the requisite skills to shine in their career in Australia.",
    image: "/assets/images/py-programs-3.webp",
    city: [
      "Adelaide",
      "Brisbane",
      "Canberra",
      "Darwin",
      "Hobart",
      "Melbourne",
      "Perth",
      "Sydney",
    ],
  },
  {
    url: "/it-program",
    name: "Professional Year in IT",
    isshow: true,
    about:
      "The ACS (IT) Professional Year is an initiative by the Department of Home Affairs. It is designed for international graduates who possess an Australian IT degree. It provides a pathway to employment in Australia.",
    image: "/assets/images/py-programs-1.webp",
    city: [
      "Adelaide",
      "Brisbane",
      "Canberra",
      "Darwin",
      "Hobart",
      "Melbourne",
      "Perth",
      "Sydney",
      "Geelong",
      "Gold Coast"
    ],
  },
  {
    url: "/engineering-program",
    name: "Professional Year in Engineering",
    isshow: true,
    about:
      "The EEA or Engineering Education Australia conceptualized the Engineering Professional Year program. This 44-week program was designed to enable international graduates in Engineering to gather essential insights about the demands and the culture of typical Australian workplaces.",
    image: "/assets/images/py-programs-2.webp",
    city: [
      "Adelaide",
      "Brisbane",
      "Canberra",
      "Darwin",
      "Hobart",
      "Melbourne",
      "Perth",
      "Sydney",
    ],
  },
  {
    url: "/naati-endorsed-courses",
    name: "NAATI Endorsed Courses",
    isshow: false,
    about: "",
    image: "",
    city: [
      "Adelaide",
      "Brisbane",
      "Canberra",
      "Darwin",
      "Hobart",
      "Melbourne",
      "Perth",
      "Sydney",
      "Geelong",
      "Gold Coast"
    ],
  },
];

import React, { useEffect, useState } from "react";
import { InstitutionsProgramSection } from "./InstitutionsProgramBox.styled";
import { CenterBtnBox, SectionSapce, Title } from "styles/General.styled";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { ArrowRightOutlineIcon } from "ui/icons";
import { useRouter } from "next/router";
import { Institutes, ProgramNames } from "helpers/InstitutesData";
import Image from "next/image";
import { PLACE_HOLDER_IMAGE } from "helpers/constant";
import dynamic from "next/dynamic";
import { Fade } from "react-awesome-reveal";
import Link from "next/link";
const EnrollDialogForm = dynamic(
  () => import("../EnrollDialogForm/EnrollDialogForm"),
  {
    ssr: true,
  }
);
declare interface Program {
  name: string;
  url: string;
  about: string;
  image: string;
  isshow: boolean;
}

const InstitutionsProgramBox = () => {
  const [instituteData, setInstituteData] = useState<Array<Program>>([]);
  const router = useRouter();
  const [open, setOpen] = React.useState(false);

  const handleEnrollFormOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data = Institutes;
  useEffect(() => {
    const program = data.find((el) => el.url == router.asPath);
    if (program) {
      const filteredInstitute = ProgramNames.filter(
        (el) =>
          !program?.name.length ||
          program.availableProgram.find((e) => e.name == el?.name)
      );
      setInstituteData(filteredInstitute);
    } else {
      const filteredInstitute = ProgramNames.filter(
        (el) => el.url !== router.asPath
      );
      setInstituteData(filteredInstitute);
    }
  }, [data]);

  return (
    <InstitutionsProgramSection>
      <SectionSapce className="space-bottom">
        <Container maxWidth="xl">
          <Fade
            damping={0}
            direction="up"
            triggerOnce={true}
            delay={500}
          >
            <Grid container columnSpacing={3} className="content-center">
              <Grid item md={12} xs={12}>
                <Fade damping={0} direction="up" triggerOnce={true} delay={500}>
                  <Title variant="h2">{`Professional Year Program's`}</Title>
                </Fade>
              </Grid>

              {(instituteData || []).map((institute: Program) => {
                return (
                  institute.isshow && (
                    <Grid item md={4} key={institute.name}>

                      <Box className="institute-program-box">
                        <Box className="institute-box-img">
                          <Image
                            src={institute.image}
                            blurDataURL={PLACE_HOLDER_IMAGE}
                            className="institute-img"
                            width={0}
                            height={0}
                            quality={100}
                            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                            alt={`${institute.name} | Professional Year Program | | Australia | Aussizz Group`}
                          ></Image>
                          {/* <img
                        className="institute-img"
                        srcSet={institute.image}
                        alt={`${institute.name} | Professional Year Program | | Australia | Aussizz Group`}
                      /> */}
                        </Box>
                        <Box className="institute-box-details">
                          <Typography className="inst-title" variant="h3">
                            {institute.name}
                          </Typography>
                          <Typography className="inst-txt" variant="body1">
                            {institute.about}
                          </Typography>
                          <Link
                            prefetch={false}
                            className="inst-link"
                            aria-label={`${institute.name} | Australia`}
                            href={institute.url}
                            title={`${institute.name} | Professional Year Program | Australia | Aussizz Group`}
                          >
                            Read More{" "}
                            <span className="screen-reader-text">
                              {institute.name}
                            </span>
                            <ArrowRightOutlineIcon className="inst-icon" />
                          </Link>
                        </Box>
                      </Box>
                    </Grid>
                  )
                );
              })}
              <Grid item md={12} xs={12}>
                <Fade damping={0} direction="up" triggerOnce={true} delay={500}>
                  <CenterBtnBox>
                    <Button
                      color="primary"
                      className="banner-btn"
                      id="ip-get-in-touch-4"
                      onClick={handleEnrollFormOpen}
                    >
                      Request Information <ArrowRightOutlineIcon />
                    </Button>
                  </CenterBtnBox>
                </Fade>
              </Grid>
            </Grid>
          </Fade>
        </Container>
      </SectionSapce>
      <EnrollDialogForm onOpen={open} onClose={handleClose} />
    </InstitutionsProgramSection>
  );
};

export default InstitutionsProgramBox;

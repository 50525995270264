import { Box, alpha, styled } from "@mui/material";

export const InstitutionsProgramSection = styled(Box)(({ theme }) => ({
  position: "relative",
  ".institute-program-box": {
    position: "relative",
    width: "100%",
    border: "1px solid #dddddd",
    borderRadius: "15px",
    padding: "0px 20px 20px",
    marginTop: "220px",
    marginBottom: "30px",
    transition: "0.3s all ease",
    boxShadow: `5px 15px 15px ${alpha(theme.colors.primaryColor, 0.0)}`,
    ".institute-box-img": {
      marginTop: "-220px",
      display: "flex",
      marginBottom: "20px",
      ".institute-img": {
        backgroundColor: "#eeeeee",
        width: "100%",
        height: "250px",
        overflow: "hidden",
        borderRadius: "15px",
        padding: "0 0px",
        objectFit: "cover",
      },
    },
    ".institute-box-details": {
      position: "relative",
      paddingBottom: "40px",
      ".inst-title": {
        fontSize: "20px",
        lineHeight: "24px",
        marginBottom: "15px",
        fontWeight: "700",
        color: theme.colors.primaryColor,
      },
      ".inst-txt": {
        fontSize: "16px",
        color: theme.colors.textColor,
        fontWeight: "500",
        display: "-webkit-box",
        WebkitLineClamp: "4",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        height: "95px",
      },
      ".inst-link": {
        position: "absolute",
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "10px",
        right: "0",
        bottom: "0",
        color: `${theme.colors.primaryColor} !important`,
        ".inst-icon": {},
        "&:hover": {
          color: `${theme.colors.secondaryColor} !important`,
        },
      },
    },
    "&:hover": {
      border: `1px solid ${theme.colors.primaryColor}`,
      transition: "0.3s all ease",
      boxShadow: `5px 15px 15px ${alpha(theme.colors.primaryColor, 0.1)}`,
    }, 
  },
  ".content-center": {
    justifyContent: 'center'
  }
}));

import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { InstitutionsInformationBox } from "./InstitutionsInformationSection.styled";
import { PointIconBox, SectionSapce, Title } from "styles/General.styled";
import Image from "next/image";
import { ArrowRightOutlineIcon } from "ui/icons";
import dynamic from "next/dynamic";
import { Fade } from "react-awesome-reveal";
import { PLACE_HOLDER_IMAGE } from "helpers/constant";
const EnrollDialogForm = dynamic(
  () => import("../EnrollDialogForm/EnrollDialogForm"),
  {
    ssr: true,
  }
);
declare interface InstitutionsInformation {
  name: string;
  icon: string
}
declare interface InstitutionsInformationSectionProps {
  name?: string;
  title2?: string;
  institutionsInformations: Array<InstitutionsInformation>;
}

const InstitutionsInformationSection = (
  props: InstitutionsInformationSectionProps
) => {
  const [open, setOpen] = React.useState(false);

  const handleEnrollFormOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SectionSapce className="space-bottom">
      <InstitutionsInformationBox>
        <Container maxWidth="xl">
          <Fade damping={0} direction="up" triggerOnce={true} delay={700}>
            <Grid container columnSpacing={3}>
              {props.title2 ? (
                <Grid item md={12} xs={12}>
                  <Title>{props.title2}</Title>
                </Grid>
              ) : (
                <Grid item md={12} xs={12}>
                  <Title variant="h2">
                    Why Aussizz for Professional Year at {props.name}?
                  </Title>
                </Grid>
              )}
              {props.institutionsInformations.map(
                (
                  institutionsInformation: InstitutionsInformation,
                  index: number
                ) => {
                  return (
                    <Grid item md={6} xs={12} key={`information-${index}`}>
                      <PointIconBox>
                        <Image
                          src={institutionsInformation.icon}
                          blurDataURL={PLACE_HOLDER_IMAGE}
                          className="points-icon-box"
                          width={0}
                          height={0}
                          quality={100}
                          style={{ height: 'auto' }}
                          alt={institutionsInformation.name}
                        ></Image>

                        <Typography variant="body1" className="points-texts">
                          {institutionsInformation.name}
                        </Typography>
                      </PointIconBox>
                    </Grid>
                  );
                }
              )}
            </Grid>
            <Grid container columnSpacing={3}>
              <Grid item md={12} xs={12}>
                <Box className="center-btn">
                  <Button
                    color="primary"
                    className="banner-btn"
                    id="ii-get-in-touch-3"
                    onClick={handleEnrollFormOpen}
                  >
                    Join Program <ArrowRightOutlineIcon />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Fade>
        </Container>
      </InstitutionsInformationBox>
      <EnrollDialogForm onOpen={open} onClose={handleClose} />
    </SectionSapce>
  );
};

export default InstitutionsInformationSection;

import React from "react";
import { ProgramDetailsSection } from "./ProgramDetails.styled";
import { SectionSapce, Title } from "styles/General.styled";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { ArrowRightOutlineIcon } from "ui/icons";
import dynamic from "next/dynamic";
import Image from "next/image";
import { Slide } from "react-awesome-reveal";
import { EffectDoteLogo, RoundLogoAnimation } from "ui";
import career from "../../../../public/assets/images/career-img.webp"
const EnrollDialogForm = dynamic(
  () => import("../EnrollDialogForm/EnrollDialogForm"),
  {
    ssr: true,
  }
);

const ProgramDetails = () => {
  const [open, setOpen] = React.useState(false);

  const handleEnrollFormOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SectionSapce className="space-bottom">
      <ProgramDetailsSection>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Slide
                damping={0}
                direction="left"
                triggerOnce={true}
                delay={100}
              >
                <Box className="program-img-box">
                  <Image
                    className="program-img"
                    src={career.src}
                    blurDataURL={career.blurDataURL}
                    width={career.width}
                    height={career.height}
                    quality={100}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    style={{ height: "100%" }}
                    alt="Engineering, IT, and Accounting graduates, Program details"
                  ></Image>
                  <RoundLogoAnimation className="about-round-effect" />
                  <EffectDoteLogo className="about-dote-effect effect-box-top-bottom" />
                  {/* <img
                  className="program-img"
                  srcSet="/assets/images/career-img.webp"
                  alt="Engineering, IT, and Accounting graduates, Program details"
                /> */}
                </Box>
              </Slide>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="program-details-box">
                <Box>
                  <Slide
                    damping={0}
                    direction="right"
                    triggerOnce={true}
                    delay={100}
                  >
                    <Title variant="h1" className="about-us-title">
                    {`Unlock your potential and secure your dream career with a Professional Year Program`}
                    </Title>
                  </Slide>
                  <Box className="about-us-details">
                    <Slide
                      damping={0}
                      direction="right"
                      triggerOnce={true}
                      delay={100}
                    >
                      <Typography variant="body1" className="details-text">
                      {`Ideal for Engineering, IT, and Accounting graduates, this program equips you with the necessary skills to excel in your chosen field and prepares you for your future professional endeavours. `}
                      </Typography>
                    </Slide>
                    <Slide
                      damping={0}
                      direction="right"
                      triggerOnce={true}
                      delay={100}
                    >
                      <Typography variant="body1" className="details-text">
                      {`For international students seeking to enter the Australian job market, a Professional Year Program is a perfect choice, particularly in cities such as Melbourne, Perth, Sydney, Adelaide, Brisbane, Canberra, Darwin, and Hobart. `}`
                      </Typography>
                    </Slide>
                    <Slide
                      damping={0}
                      direction="right"
                      triggerOnce={true}
                      delay={100}
                    >
                      <Typography variant="body1" className="details-text">
                      {`Completing a Professional Year Program offers Engineering, IT, and Accounting Graduates a competitive edge, as well as five points towards their Australian Permanent Residency application. The program is 44 weeks long, with a 12-week internship period that enables applicants to acclimate to the workplace environment and settle in. `}
                      </Typography>
                    </Slide>
                    <Slide
                      damping={0}
                      direction="right"
                      triggerOnce={true}
                      delay={100}
                    >
                      <Typography variant="body1" className="details-text">
                        {`If you're an Engineering, IT, or Accounting Graduate seeking to enhance your capabilities and boost your chances of finding suitable employment, consider enrolling in a Professional Year Program. It will equip you with valuable skills and experience that will help you succeed in your future career. `}
                      </Typography>
                    </Slide>
                    <Box className="">
                      <Slide
                        damping={0}
                        direction="right"
                        triggerOnce={true}
                        delay={100}
                      >
                        <Button
                          color="primary"
                          className="banner-btn"
                          id="pdp-get-in-touch"
                          onClick={handleEnrollFormOpen}
                        >
                          Learn More <ArrowRightOutlineIcon />
                        </Button>
                      </Slide>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ProgramDetailsSection>
      <EnrollDialogForm onOpen={open} onClose={handleClose} />
    </SectionSapce>
  );
};

export default ProgramDetails;

import { Box, styled } from "@mui/material";
export const MainBannerBox = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(90deg, rgba(248,222,219,1) 22%, rgba(226,239,249,1) 45%)',
  position: "relative",
  overflow: "hidden",
  height: "770px",
  [theme.breakpoints.down("xl")]: {
    height: "660px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "500px",
  },
  ".center-text": {
    position: "relative",
    height: "770px",
    display: "flex",
    alignItems: "center",
    paddingTop: "100px",
    [theme.breakpoints.down("xl")]: {
      height: "660px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "500px",
    },
    [theme.breakpoints.down("md")]: {
      position: "relative",
      zIndex: "1",
      width: "100%",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  ".banner-text": {
    position: "relative",
    zIndex: "1",
    ".banner-title-text": {
      position: "relative",
      display: "inline-block",
      fontSize: "56px",
      lineHeight: "60px",
      fontWeight: "600",
      color: theme.colors.primaryColor,
      marginBottom: "15px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "52px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "38px",
        lineHeight: "45px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
        lineHeight: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px",
        lineHeight: "32px",
      },
      ".white-border": {
        position: "relative",
        display: "block",
        textTransform: "uppercase",
        fontWeight: "600",
        color: theme.colors.secondaryColor,
        zIndex: "2",
      },
    },
    ".banner-details-text": {
      fontSize: "18px",
      width: "470px",
      position: "relative",
      paddingLeft: "25px",
      fontWeight: "500",
      [theme.breakpoints.down("lg")]: {
        fontSize: "16px",
        width: "400px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "0",
        textAlign: "center",
        maxWidth: "400px",
        width: "auto",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "3px",
        backgroundColor: theme.colors.blackPrimary,
        left: "0",
        top: "50%",
        height: "calc(100% - 10px)",
        transform: "translate(5px, -50%)",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
    ".banner-btn": {
      width: "250px",
      marginTop: "40px",
      svg: {
        marginLleft: "5px",
      },
    },
  },
  ".banner-user-img": {
    position: "absolute",
    right: "-80px",
    top: "0",
    width: "auto",
    height: "770px",
    objectFit: "contain",
    [theme.breakpoints.down("xl")]: {
      right: "-100px",
      height: "660px",
    },
    [theme.breakpoints.down("lg")]: {
      right: "-80px",
      height: "500px",
    },
    [theme.breakpoints.down("md")]: {
      right: "0",
      top: "inherit",
      bottom: "0",
      height: "auto",
      width: "100%",
      objectFit: "cover",
      opacity: "0.05",
    },
  },
  ".dote-effect": {
    color: "transparent",
    position: "absolute",
    bottom: "60px",
    left: "20px",
    width: "200px",
    height: "200px",
    [theme.breakpoints.down("xl")]: {
      width: "160px",
      height: "160px",
    },
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  ".banner-shape-box": {
    color: "transparent",
    position: "absolute",
    bottom: "0px",
    left: "0",
    width: "100%",
    height: "auto",
  },
}));

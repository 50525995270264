import { Layout } from "common/Layout";

import banner from "../../../public/assets/images/banner-user-img.webp"
import shape from "../../../public/assets/images/main-banner-shape.svg"
import { Institutes, ProgramNames } from "helpers/InstitutesData";
import MainBanner from "common/Components/MainBanner/MainBanner";
import InstituteFinder from "common/Components/InstituteFinder/InstituteFinder";
import InstituteTable from "common/Components/InstituteTable/InstituteTable";
import ProgramDetails from "common/Components/ProgramDetails/ProgramDetails";
import AboutUsDetails from "common/Components/AboutUsDetails/AboutUsDetails";
import InstitutionsProgramBox from "common/Components/InstitutionsProgramBox/InstitutionsProgramBox";
import InstitutionsInformationSection from "common/Components/InstitutionsInformationSection/InstitutionsInformationSection";

export const Home = () => {
  return (
    <>
      <MainBanner banner={banner} shape={shape} />
      <InstituteFinder Institutes={Institutes} ProgramNames={ProgramNames} />
      <InstituteTable sectionClass={'space-bottom'} />
      <ProgramDetails />
      <AboutUsDetails />
      <InstitutionsProgramBox />
      <InstitutionsInformationSection
        title2="Why Aussizz Group?"
        institutionsInformations={[
          {
            name: `Avail Scholarship through us`,
            icon: '/assets/Icons50/industryv1.webp',
          },
          {
            name: `We have enriched the lives of over 20,000 successful clients`,
            icon: '/assets/Icons50/Employee-Unwind-Session.webp',
          },
          {
            name: `Reward on referring a friend`,
            icon: '/assets/Icons50/studentv1.webp',
          },
          {
            name: `One FREE consultation`,
            icon: '/assets/Icons50/system.webp',
          },
          {
            name: `Free PTE Materials `,
            icon: '/assets/Icons50/system.webp',
          },
        ]}
      />
    </>
  );
};

Home.getLayout = Layout;
Home.layoutOptions = {
  pageTitle:
    "Professional Year Program | IT, Accounting & Engineering | Australia | Aussizz Group",
  pageDescription:
    "Are you an international graduate in Australia in Engineering, Accounting or IT field? Getting a Professional Year certificate can help you in PR application.",
  pageKeywords:
    `Professional Year Program,py ,Professional Year ,IT , Accounting, Engineering, Aussizz Group, Australia,
    Professional Year Program Accounting,
Professional Year Program IT,
Professional Year Program Engineering,
Professional Year in Australia,
Skilled Migration Internship Program,
PY Program Accounting,
PY Program IT,
PY Program Engineering,
Professional Internship Program,
Professional Year Visa,
PR Pathway through Professional Year,
Professional Year Program Benefits,
PY Program Requirements,
Skilled Migration Program Australia,
PY Program for International Students,
Professional Development Program,
Professional Year Program Eligibility`,
  imageUrl: '/assets/images/logo.webp'
};

import { Box, alpha, styled } from "@mui/material";

export const InstituteFinderSection = styled(Box)(({ theme }) => ({
  position: "relative",
  ".institute-title": {
    position: "relative",
    paddingBottom: "30px",
    marginBottom: "20px",
    ".imain-title": {
      marginBottom: "0",
      paddingBottom: "15px",
      "&:before,&:after": {
        display: "none",
      },
    },
    ".sub-title": {
      textAlign: "center",
      width: "45%",
      margin: "0 auto",
      fontWeight: "500",
      lineHeight: "28px",
      fontSize: "18px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "90px",
      height: "3px",
      backgroundColor: theme.colors.secondaryColor,
      borderRadius: "15px",
      left: "50%",
      bottom: "3px",
      transform: "translate(-50%, 0%)",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      width: "60px",
      height: "10px",
      backgroundColor: theme.colors.primaryColor,
      borderRadius: "15px",
      left: "50%",
      bottom: "0",
      transform: "translate(-50%, 0%)",
    },
  },
  ".institute-form": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    margin: "0 auto 50px",
    [theme.breakpoints.down("lg")]: {
      width: "60%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto 30px",
    },
    ".form-group": {
      margin: "0 10px 0 10px",
      [theme.breakpoints.down("md")]: {
        margin: "0 0px 20px 0px",
        width: "100%",
      },
    },
  },
  ".feature-box": {
    position: "relative",
    padding: "20px 20px",
    borderRadius: "15px",
    marginBottom: "40px",
    marginTop: "60px",
    boxShadow: `5px 15px 15px ${alpha(theme.colors.primaryColor, 0.0)}`,
    border: `1px solid #eeeeee`,
    // borderBottom: `5px solid #eeeeee`,
    transition: "0.3s all ease",
    [theme.breakpoints.down("xl")]: {
      marginBottom: "30px",
    },
    ".institute-img": {
      marginTop: "-80px",
      marginBottom: "20px",
      backgroundColor: theme.colors.white,
      padding: "10px 20px",
      border: "1px solid #eee",
      borderRadius: "20px",
      display: "flex",
      justifyContent: "center",
      ".inst-img": {
        width: "150px",
        height: "80px",
        objectFit: "contain",
      },
    },
    ".feature-icon": {
      background:
        "linear-gradient(144deg, #283676 17.85%, rgba(217, 217, 217, 0) 596.23%)",
      width: "55px",
      height: "55px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      marginBottom: "15px",
    },
    ".institute-box-title": {
      position: "relative",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "600",
      marginBottom: "15px",
      paddingBottom: "20px",
      color: theme.colors.primaryColor,
      [theme.breakpoints.down("xl")]: {
        fontSize: "18px",
        marginBottom: "15px",
        paddingBottom: "15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "40px",
        height: "3px",
        backgroundColor: theme.colors.primaryColor,
        bottom: "0",
        borderRadius: "10px",
      },
    },
    ".institute-texts": {
      color: theme.colors.textColor,
      display: "-webkit-box",
      maxWidth: "100%",
      height: "68px",
      margin: "0 auto",
      fontWeight: "500",
      fontSize: "16px",
      WebkitLineClamp: "3",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: "24px",
    },
    ".institute-link-box": {
      position: "relative",
      textAlign: "right",
      marginTop: "10px",
      ".institute-link": {
        fontSize: "14px",
        fontWeight: "500",
        color: `${theme.colors.primaryColor} !important`,
        transition: "0.3s all ease",
        "&:hover": {
          transition: "0.3s all ease",
          color: `${theme.colors.secondaryColor} !important`,
        },
      },
    },
    "&:hover": {
      border: `1px solid ${theme.colors.primaryColor}`,
      // borderBottom: `5px solid ${theme.colors.primaryColor}`,
      boxShadow: `5px 15px 15px ${alpha(theme.colors.primaryColor, 0.1)}`,
      transition: "0.3s all ease",
    },
  },
  ".institute-dote-effect": {
    position: "absolute",
    top: "0",
    right: "40px",
    width: "160px",
    transform: "translate(-50%, -50%)",
    zIndex: "-1",
  },
}));

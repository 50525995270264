import { InstituteFinderSection } from "./InstituteFinder.styled";
import {
  Box,
  Container,
  Grid,
  Typography
} from "@mui/material";
import { SectionSapce, Title } from "styles/General.styled";
import {
  PLACE_HOLDER_IMAGE,
} from "helpers/constant";
import Link from "next/link";
import Image from "next/image";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

declare interface Institute {
  name: string;
  url: string;
  sDescription: string;
  logo: string;
  availableProgram: any[];
}

declare interface InstituteFinderProps {
  Institutes: any;
  ProgramNames: any;
}
const InstituteFinder = (props: InstituteFinderProps) => {
  const { Institutes, ProgramNames } = props;
  const [instituteData, setInstituteData] = useState<Array<Institute>>([]);
  const router = useRouter();
  const data = Institutes;

  useEffect(() => {
    const program = ProgramNames.find((el: any) => el.url == router.asPath);
    const filteredInstitute = data.filter(
      (el: any) =>
        (!program?.name.length || el.availableProgram.find((e: any) => e.name == program.name))
    );
    setInstituteData(filteredInstitute);
  }, []);
  return (
    <SectionSapce className="space-top space-bottom">
      <InstituteFinderSection>
        <Container maxWidth="xl">
          <Grid container columnSpacing={3}>
            <Grid item sm={12} xs={12}>
              <Box className="institute-title">
                <Title variant="h1" className="imain-title">
                  Our Partners
                </Title>
              </Box>
            </Grid>

            {/*  */}
            {(instituteData || [])
              .map((institute: Institute) => {
                return (
                  <Grid item lg={3} md={4} sm={6} key={institute.name}>
                    <Box className="feature-box">
                      <Box className="institute-img">
                        <Image
                          src={institute.logo}
                          blurDataURL={PLACE_HOLDER_IMAGE}
                          className="inst-img"
                          width={0}
                          height={0}
                          quality={100}
                          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                          alt={`${institute.name} | Professional Year Program | | Australia | Aussizz Group`}
                        ></Image>
                      </Box>
                      <Typography
                        variant="h2"
                        className="institute-box-title"
                      >
                        {institute.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        className="institute-texts"
                      >
                        {institute.sDescription}
                      </Typography>
                      <Box className="institute-link-box">
                        <Link
                          prefetch={false}
                          passHref
                          aria-label={`${institute.name} | Australia`}
                          href={institute.url}
                          title={`${institute.name} | Professional Year Program | Australia | Aussizz Group`}
                          className="institute-link"
                        >
                          Read More{" "}
                          <span className="screen-reader-text">
                            {institute.name}
                          </span>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            {/*  */}
          </Grid>
        </Container>
      </InstituteFinderSection>
    </SectionSapce>
  );
};

export default InstituteFinder;

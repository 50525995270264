import { Box, styled } from "@mui/material";

export const AboutUsBox = styled(Box)(({ theme }) => ({
  position: "relative",
  marginTop: "40px",
  ".about-us-box": {
    justifyContent: "end",
  },
  ".about-img-box": {
    position: "relative",
    textAlign: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".about-img": {
      width: "630px",
      [theme.breakpoints.down("xl")]: {
        width: "580px",
      },
      [theme.breakpoints.down("lg")]: {
        width: "500px",
      },
      [theme.breakpoints.down("md")]: {
        width: "300px",
      },
    },
    ".about-round-effect": {
      width: "450px",
      height: "auto",
      position: "absolute",
      top: "-60px",
      right: "-90px",
      zIndex: "-1",
      [theme.breakpoints.down("xl")]: {
        width: "350px",
        top: "-30px",
        right: "0px",
      },
    },
    ".about-dote-effect": {
      width: "210px",
      height: "200px",
      position: "absolute",
      bottom: "130px",
      left: "-20px",
      zIndex: "-1",
      overflow: "hidden",
      [theme.breakpoints.down("xl")]: {
        bottom: "70px",
        left: "-10px",
      },
    },
  },
  ".about-quotation": {
    position: "relative",
    marginBottom: "30px",
    "&.space-zero": {
      [theme.breakpoints.down("md")]: {
        marginBottom: "0px",
      },
    },
    ".about-q": {
      fontSize: "18px",
      fontWeight: "600",
      marginBottom: "10px",
    },
    ul: {
      position: "relative",
      padding: "0",
      li: {
        padding: "0",
        marginBottom: "10px",
      },
    },
    ".point-ans": {
      position: "relative",
      fontSize: "16px",
      color: theme.colors.textColor,
      fontWeight: "500",
      paddingLeft: "15px",
      "&:before": {
        content: "''",
        position: "absolute",
        width: "5px",
        height: "5px",
        backgroundColor: theme.colors.subTextColor,
        borderRadius: "50%",
        left: "0",
        top: "8px",
      },
    },
  },
  ".point-answer-box": {
    position: "relative",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    ".point-ans-box": {
      width: "100%",
      paddingRight: "20px",
      display: "flex",
      alignItems: "flex-start",
      position: "relative",
      marginBottom: "10px",
      [theme.breakpoints.down("lg")]: {
        width: "50%",
        marginBottom: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: "10px",
      },
      ".ans-texts": {
        fontSize: "18px",
        fontWeight: "600",
        marginTop: "2px",
        marginLeft: "10px",
        color: theme.colors.primaryColor,
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
        },
      },
      ".ans-icon": {
        fontSize: "24px",
        marginRight: "10px",
      },
    },
  },
  ".about-img-order": {
    [theme.breakpoints.down("md")]: {
      order: "0",
    },
  },
  ".about-text-order": {
    [theme.breakpoints.down("md")]: {
      order: "1",
    },
  },
  ".about-points-order": {
    [theme.breakpoints.down("md")]: {
      order: "2",
    },
  },
}));

import { Box, styled } from "@mui/material";

export const ProgramDetailsSection = styled(Box)(({ theme }) => ({
  position: "relative",
  ".program-details-box": {
    position: "relative",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  ".program-img-box": {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
    },
    ".program-img": {
      maxWidth: "720px !important",
      position: "relative",
      right: "-30px",
      [theme.breakpoints.down("xl")]: {
        maxWidth: "650px !important",
        right: "0px",
      },
      [theme.breakpoints.down("lg")]: {
        maxWidth: "550px !important",
        right: "0px",
        left: "-40px",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "100% !important",
        right: "inherit",
        left: "inherit",
      },
    },
    ".about-round-effect": {
      width: "330px",
      height: "auto",
      top: "0px",
      right: "20px",
      zIndex: "-1",
    },
    ".about-dote-effect": {
      bottom: "60px",
      left: "10px",
      zIndex: "-1",
    },
  },
  ".about-us-details": {
    position: "relative",
    ".details-text": {
      fontSize: "16px",
      marginBottom: "20px",
      fontWeight: "500",
      color: theme.colors.textColor,
      [theme.breakpoints.down("xl")]: {
        marginBottom: "15px",
      },
    },
  },
  ".center-btn": {
    position: "relative",
    textAlign: "center",
    marginTop: "30px",
  },
  ".about-round-effect": {
    width: "450px",
    height: "auto",
    position: "absolute",
    top: "-60px",
    right: "-90px",
    zIndex: "-1",
  },
  ".about-dote-effect": {
    width: "210px",
    height: "200px",
    position: "absolute",
    bottom: "130px",
    left: "-20px",
    zIndex: "-1",
    overflow: "hidden",
  },
}));

import React from "react";
import { MainBannerBox } from "./MainBanner.styled";
import {
  Box, Button, Container, Grid, Typography
} from "@mui/material";
import { ArrowRightOutlineIcon } from "ui/icons";
import dynamic from "next/dynamic";
import Image, { StaticImageData } from "next/image";
import { PLACE_HOLDER_IMAGE } from "helpers/constant";
const EnrollDialogForm = dynamic(
  () => import("../EnrollDialogForm/EnrollDialogForm"),
  {
    ssr: true,
  }
);

declare interface MainBannerProps {
  shape: StaticImageData;
  banner: StaticImageData
}

const MainBanner = (props: MainBannerProps) => {
  const { banner, shape } = props;
  const [open, setOpen] = React.useState(false);

  const handleEnrollFormOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MainBannerBox>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
            <Box className="center-text">
              <Box className="banner-text">
                <Typography variant="h2" className="banner-title-text">
                  <span className="white-border">PROFESSIONAL YEAR</span>
                  <span> Program in Australia</span>
                </Typography>
                <Typography className="banner-details-text">
                  Are you an international graduate in Australia in the field
                  of Accounting, IT & Engineering?
                </Typography>
                <Typography className="banner-details-text">
                  Getting a Professional Year certificate can help you in PR
                  application.
                </Typography>
                <Button
                  color="secondary"
                  className="banner-btn"
                  id="main-banner-get-in-touch"
                  onClick={handleEnrollFormOpen}
                >
                  Start Your Journey <ArrowRightOutlineIcon />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Image
        className="banner-user-img"
        src={banner.src}
        blurDataURL={banner.blurDataURL}
        width={banner.height}
        height={banner.width}
        rel="preload"
        quality={100}
        loading="eager"
        alt="Getting a Professional Year
      certificate can help you in PR application."
        style={{ height: "auto" }} 
        priority={true}
      ></Image>

      <Image
        className="banner-shape-box"
        src={shape}
        blurDataURL={PLACE_HOLDER_IMAGE}
        width={100}
        height={100}
        rel="preload"
        loading="eager"
        quality={100}
        style={{ height: "auto" }} 
        alt="Getting a Professional Year
      certificate can help you in PR application."
        priority={true}
      ></Image>
      <EnrollDialogForm onOpen={open} onClose={handleClose} />
    </MainBannerBox >
  );
};

export default MainBanner;

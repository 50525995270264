import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography
} from "@mui/material";
import { AboutUsBox } from "./AboutUsDetails.styled";
import { SectionSapce, Title } from "styles/General.styled";

import Image from "next/image";
import { EffectDoteLogo, RoundLogoAnimation } from "ui";
import { Slide } from "react-awesome-reveal";
import enroll from "../../../../public/assets/images/enroll-img.webp"
const AboutUsDetails = () => {
  return (
    <SectionSapce className="space-bottom">
      <AboutUsBox>
        <Container maxWidth="xl">
          <Grid container columnSpacing={3}>
            <Grid item md={6} className="about-text-order">
              <Box className="about-quotation">
                <Slide
                  damping={0}
                  direction="left"
                  triggerOnce={true}
                  delay={100}
                >
                  <Title variant="h1" className="about-us-title">
                    What are the basic requirements to apply for a Professional
                    Year Program?
                  </Title>
                </Slide>
                <Slide
                  damping={0}
                  direction="left"
                  triggerOnce={true}
                  delay={100}
                >
                  <List>
                    <ListItem>
                      <Typography variant="body1" className="point-ans">
                        {`The first criterion is that the candidate should have a Bachelor’s or Master’s degree in either Accounting, IT or Engineering disciplines.`}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1" className="point-ans">
                      The applicants need to score overall of 6 bands in IELTS or 50 overall in PTE to apply for Post Study Work stream 485
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1" className="point-ans">
                      The applicant must score 6 bands in each module of IELTS for Temporary Graduate Visa Subclass 485, to get registered in a Professional Year Program. The applicant must also have more than 12 months on their 485 visa. 
                      </Typography>
                    </ListItem>
                  </List>
                </Slide>
              </Box>
              <Box className="about-quotation">
                <Slide
                  damping={0}
                  direction="left"
                  triggerOnce={true}
                  delay={100}
                >
                  <Title variant="h1" className="about-us-title">
                    What are the benefits of Professional Year?
                  </Title>
                </Slide>
                <Slide
                  damping={0}
                  direction="left"
                  triggerOnce={true}
                  delay={100}
                >
                  <List>
                    <ListItem>
                      <Typography variant="body1" className="point-ans">
                        The most important benefit of the Professional Year
                        Program is that upon completion, applicants gain five
                        points towards their Australia PR application.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1" className="point-ans">
                        The program helps you gain an understanding of the
                        Australian culture.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1" className="point-ans">
                        It adds value to your CV.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1" className="point-ans">
                        Builds your professional skills through the internship.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1" className="point-ans">
                        Through the internship program, you get the opportunity
                        to learn professional skills and get a Professional Year
                        certificate.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1" className="point-ans">
                        You also get to develop your network with industry
                        professionals.
                      </Typography>
                    </ListItem>
                  </List>
                </Slide>
              </Box>

            </Grid>
            <Grid item md={6} xs={12} className="about-img-order">
              <Slide
                damping={0}
                direction="right"
                triggerOnce={true}
                delay={100}
              >
                <Box className="about-img-box">
                  <Image
                    src={enroll.src}
                    blurDataURL={enroll.blurDataURL}
                    className="about-img"
                    width={enroll.width}
                    height={enroll.height}
                    quality={100}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    style={{ height: "auto" }} // optional
                    alt="all about professional year program in australia with aussizz group"
                  ></Image>
                  <RoundLogoAnimation className="about-round-effect" />
                  <EffectDoteLogo className="about-dote-effect effect-box-top-bottom" />
                </Box>
              </Slide>
            </Grid>

          </Grid>
        </Container>
      </AboutUsBox>
    </SectionSapce>
  );
};

export default AboutUsDetails;

import { Box, styled } from "@mui/material";

export const InstitutionsInformationBox = styled(Box)(() => ({
  position: "relative",
  ".center-btn": {
    position: "relative",
    textAlign: "center",
    marginTop: "20px",
  },
}));
